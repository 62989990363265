<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Spending Target Transactions</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Spending</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Transactions</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>User ID</th>
                        <th>Spending Target ID</th>
                        <th>Amount</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="transactions.length === 0">
                        <td colspan="6" class="text-center">No transactions available</td>
                      </tr>
                      <tr v-else v-for="(transaction, index) in transactions" :key="index">
                        <td>{{ transaction.id }}</td>
                        <td>
                          <router-link
                            v-if="transaction?.user != null"
                            :to="'/profile/'+transaction?.user?.id"
                          >{{ transaction?.user?.first_name }} {{ transaction?.user?.last_name }}</router-link>
                          <span v-else>{{transaction?.user_id}}</span>
                        </td>
                        <td>{{ transaction?.spending_target?.description }}</td>
                        <td>{{ transaction.amount | currency }}</td>
                        <td>{{ transaction.created_at }}</td>
                        <td>{{ transaction.updated_at }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                   v-if="transactions?.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: true, // Loader flag
      transactions: [], // Array to store API data
      currentPage: 1,
      totalPages: 1,
    };
  },
  mounted() {
    this.fetchTransactions();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      this.getTransactions();
    },
    fetchTransactions() {
      let url = this.$store.getters.baseURL + "achievements/admin/spending_targets/transactions?page=" + this.currentPage
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            this.transactions = result.data.data; // Nested inside `data`
          } else {
            console.error(result.message || "Failed to fetch transactions");
            Swal.fire("Error", result.message || "Failed to fetch transactions", "error");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          Swal.fire("Error", "An error occurred while fetching transactions", "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
