<template>
   

    <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Spending targets</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Spending</li>
                 
                    <li class="breadcrumb-item active text-primary" aria-current="page"></li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                                <button
                                    class="btn btn-secondary mr-5"
                                    @click.prevent="editing = false; displayForm(null)"
                                >Create</button>
                
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>Description</th>
                        <th>Time Frame</th>
                        <th>Target Amount</th>
                        <th>Reward</th>
                        <th>Type</th>
                        
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="spendingTargets.length === 0">
                            <td colspan="6" class="text-center">No spending targets available</td>
                        </tr>
                        <tr v-else v-for="(target, index) in spendingTargets" :key="index">
                            <td>{{ target.id }}</td>
                            <td>{{ target.description }}</td>
                            <td>{{ target.time_frame }}</td>
                            <td>{{ target.target_amount | currency }}</td>
                            <td><b>{{ target.reward | currency}}</b></td>
                            <td>{{ target.type }}</td>
                            <td>
                                <button
                                    class="btn btn-secondary mr-5"
                                    @click.prevent="editing = true; displayForm(target)"
                                >&#9998;</button>
                                <button
                                    class="btn btn-danger"
                                    @click.prevent="deleteCashback(target.id,index)"
                                >🗑</button>
                            </td>
                        </tr>
                  </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
  </template>
  
  <script>
import Swal from 'sweetalert2';

  export default {
    data() {
      return {
        isLoading: true, // Loader flag
        spendingTargets: [], // Array to store API data
      };
    },
    mounted() {
      this.fetchSpendingTargets();
    },
    methods: {
      fetchSpendingTargets() {
        const url = this.$store.getters.baseURL + "achievements/admin/spending_targets";
        fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status) {
              this.spendingTargets = result.data;
            } else {
              console.error(result.message || "Failed to fetch spending targets");
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      async displayForm(target) {
        const isEditing = this.editing;

        // Extract properties from the target object
        const targetDescription = target?.description || "";
        const targetType = target?.type || "";
        const timeFrame = target?.time_frame || "";
        const reward = target?.reward || "";
        const targetAmount = target?.target_amount || "";

        // Generate options dynamically
        const timeFrames = ["day", "month", "year"];
        const typeOptions = ["ALL", "AIRTIME", "DATA", "SME", "CABLE", "POWER"];
        const generateOptions = (options, selectedValue) => {
            return options
            .map(
                (option) =>
                `<option value="${option}" ${
                    option === selectedValue ? "selected" : ""
                }>${this.capitalize(option)}</option>`
            )
            .join("");
        };

        const timeFrameOptions = generateOptions(timeFrames, timeFrame);
        const typeOptionsHtml = generateOptions(typeOptions, targetType);

        // Show SweetAlert form
        const { value: formValues } = await Swal.fire({
            title: isEditing ? "Edit Target" : "Create Target",
            html: `
            <div class="form-group">
                <input 
                id="description" 
                type="text" 
                placeholder="Description" 
                value="${targetDescription}" 
                class="swal2-input" 
                autocomplete="off">
            </div>
            <div class="form-group">
                <input 
                id="reward" 
                type="number" 
                placeholder="Reward" 
                value="${reward}" 
                class="swal2-input" 
                autocomplete="off">
            </div>
             <div class="form-group">
                <input 
                id="target_amount" 
                type="number" 
                placeholder="Target Amount" 
                value="${targetAmount}" 
                class="swal2-input" 
                autocomplete="off">
            </div>
            <div class="form-group">
                <select id="time_frame" class="swal2-input">
                <option value="">Select Time Frame</option>
                ${timeFrameOptions}
                </select>
            </div>
            <div class="form-group">
                <select id="type" class="swal2-input">
                <option value="">Select Type</option>
                ${typeOptionsHtml}
                </select>
            </div>
            `,
            focusConfirm: false,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: () => {
            return {
                description: document.getElementById("description").value,
                reward: document.getElementById("reward").value,
                time_frame: document.getElementById("time_frame").value,
                type: document.getElementById("type").value,
                target_amount: document.getElementById("target_amount").value,
            };
            },
        });

        if (formValues) {
            console.log(formValues);

            if (isEditing) {
            this.editTarget(formValues, target.id);
            } else {
            this.createTarget(formValues);
            }
        }
    },

    async createTarget(targetData) {
        try {
            const response = await fetch(
            this.$store.getters.baseURL +  "achievements/admin/spending_targets",
            {
                method: "POST",
                headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
                "Content-Type": "application/json",
                },
                body: JSON.stringify(targetData),
            }
            );

            if (!response.ok) {
            const errorData = await response.json();
            console.error("Error creating target:", errorData);
            Swal.fire("Error", errorData.message || "Failed to create target", "error");
            return;
            }

            const responseData = await response.json();
            console.log("Target created successfully:", responseData);
            Swal.fire("Success", "Target created successfully", "success");
        } catch (error) {
            console.error("Unexpected error:", error);
            Swal.fire("Error", "Unexpected error occurred while creating target", "error");
        }
        window.location.reload();
    },

    async editTarget(targetData, targetId) {
        try {
            const response = await fetch(
            this.$store.getters.baseURL + `achievements/admin/spending_targets/${targetId}/update_targets`,
            {
                method: "PUT",
                headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
                "Content-Type": "application/json",
                },
                body: JSON.stringify(targetData),
            }
            );

            if (!response.ok) {
            const errorData = await response.json();
            console.error("Error updating target:", errorData);
            Swal.fire("Error", errorData.message || "Failed to update target", "error");
            return;
            }

            const responseData = await response.json();
            console.log("Target updated successfully:", responseData);
            Swal.fire("Success", "Target updated successfully", "success");
        } catch (error) {
            console.error("Unexpected error:", error);
            Swal.fire("Error", "Unexpected error occurred while updating target", "error");
        }
        window.location.reload();
    }


  }
}
  </script>
  
  <style scoped>
  /* Add any desired styles here */
  .text-center {
    text-align: center;
  }
  </style>
  